import { BlobDocument, Uuid } from 'meya'
import { BaseService } from '@service/common/base.services'
import { User } from '@entity/settings/user.entity'

export class UserService extends BaseService {
  protected static apiDefaultSearchPath = '/v1/core/account/user/search'

  static create(name: string, surname: string, email: string, isAdmin: boolean): Promise<User> {
    const form = {
      name,
      email,
      surname,
      is_admin: isAdmin
    }
    return this.post(`/v1/core/account/user`, form).then(data => User.fromAPI(data))
  }

  static update(id: Uuid, name: string, surname: string, email: string, isAdmin: boolean): Promise<User> {
    const form = {
      name,
      email,
      surname,
      is_admin: isAdmin
    }

    return this.put(`/v1/core/account/user/${id.getValue()}`, form).then(data => User.fromAPI(data))
  }

  static changePasswordMe(oldPassword: string, newPassword: string): Promise<User> {
    const form = {
      old_password: oldPassword,
      new_password: newPassword
    }

    return this.post(`/v1/core/account/user/me/changePassword`, form).then(data => User.fromAPI(data))
  }

  static getProfileImage(id: Uuid): Promise<BlobDocument> {
    return this.get(`/v1/core/account/user/${id.getValue()}/profileImage`, {
      responseType: 'blob',
      hideError: true
    }).then(data => new BlobDocument(data))
  }
  static updateProfileImage(id: Uuid, image: File | Blob): Promise<User> {
    const formData = new FormData()
    formData.append('file', image)
    return this.post(`/v1/core/account/user/${id.getValue()}/profileImage`, formData).then(data => User.fromAPI(data))
  }

  static addRoles(id: Uuid, roles: string[]): Promise<User> {
    return this.post(`/v1/core/account/user/${id.getValue()}/addRoles`, { roles }).then(data => User.fromAPI(data))
  }

  static remove(id: Uuid): Promise<User> {
    return this.delete(`/v1/core/account/user/${id.getValue()}`, null).then(data => User.fromAPI(data))
  }

  static getById(id: Uuid): Promise<User> {
    return this.get(`/v1/core/account/user/${id.getValue()}`).then(data => User.fromAPI(data))
  }

  static recovery(email: string): Promise<void> {
    return this.post(`/v1/core/account/user/recover`, { email }, { public: true }).then(data => {
      console.log('recovery', data)
    })
  }
}
