<template>
  <template v-if="!loadingStore">
    <div class="main-filters">
      <div>
        <lca-input
          v-model="table.search"
          :label="$t('core.searchBy', { items: table.searchByTxt.join(', ') })"
          :placeholder="$t('core.searchBy', { items: table.searchByTxt.join(', ') })"
          :clearable="true"
          density="default"
        />
      </div>
      <div class="actions">
        <div class="slot">
          <slot name="actions"></slot>
        </div>

        <lca-fade-transition v-if="slots.filters">
          <template v-if="!table.visibleFilters && table.hasFiltersApply">
            <lca-badge bordered color="secondary">
              <lca-button type="primary" icon size="small" class="m-l-10" @click="table.toggleFilters">
                <i class="fa-solid fa-filter"></i>
              </lca-button>
            </lca-badge>
          </template>

          <lca-button
            v-else-if="!table.visibleFilters"
            icon
            type="primary"
            density="default"
            class="m-l-10"
            @click="table.toggleFilters"
          >
            <i class="fa-solid fa-filter"></i>
          </lca-button>
        </lca-fade-transition>
      </div>
    </div>

    <lca-data-table-server
      v-model:items-per-page="table.itemsPerPage"
      v-model:page="table.page"
      :headers="props.headers"
      :items-length="table.totalItems"
      :items="table.serverItems"
      :loading="table.loading"
      :multiSort="true"
      :itemsPerPageText="$t('core.itemsPerPage')"
      :search="table.search"
      hover
      :items-per-page-options="pageOptions"
      show-current-page
      @update:options="table.changeModel"
      :loading-text="$t('core.loading')"
      @click:row="rowClickEmit"
      density="compact"
      class="lca-data-table-server"
      :sortBy="props.sortBy"
    >
      <template v-for="header in headers" v-slot:[`item.${header.key}`]="{ value }">
        <slot :name="`item.${header.key}`" :value="value">{{ value }}</slot>
      </template>
    </lca-data-table-server>

    <lca-navigation-drawer v-model="table.visibleFilters" location="right" class="lca-filters-drawer" width="320">
      <div class="txt-right">
        <lca-button @click="table.toggleFilters" text>
          {{ $t('core.hide') }}
        </lca-button>
      </div>

      <div v-on:keyup.enter="table.load()">
        <slot name="filters" v-bind="table.filters"></slot>
      </div>

      <lca-button
        type="primary"
        class="w100 m-t-10"
        @click="table.load"
        v-on:keyup.enter="table.load"
        density="default"
      >
        {{ $t('core.search') }}
      </lca-button>

      <lca-button class="w100 m-t-10" @click="table.clearFilters" density="default">
        {{ $t('core.cleanFilters') }}
      </lca-button>
    </lca-navigation-drawer>
  </template>
</template>

<script setup lang="ts">
import { ref, onBeforeMount, useSlots } from 'vue'

interface PropsType {
  tableStore: string
  headers: object[]
  sortBy: object[]
}

const props = defineProps<PropsType>()
const emit = defineEmits(['click-row'])
const slots = useSlots()

const loadingStore = ref(true)
let table = null

onBeforeMount(async () => {
  const { tableStore } = await import(`@stores/tables/${props.tableStore}.table`)
  table = tableStore()
  table.setHeaders(props.headers)
  loadingStore.value = false
})

const rowClickEmit = (event, row) => {
  emit('click-row', row)
}

defineExpose({
  refresh: () => {
    table.refresh()
  }
})

const pageOptions = [5, 10, 15, 25, 50, 100]
</script>

<style lang="scss" scoped>
.main-filters {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  > div:first-child {
    width: 100%;
    padding-right: 20px;
  }

  .actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}
</style>

<style lang="scss">
.lca-data-table-server {
  tbody {
    tr {
      &:nth-of-type(odd) {
        background-color: $table-striped;
      }
    }
  }
}
.lca-filters-drawer {
  padding: 10px;
  background-color: var(--drawer-bg-color);
}
</style>
