import { RouteRecordRaw } from 'vue-router'

const paths: Array<RouteRecordRaw> = [
  {
    path: 'profile',
    name: 'profile-main',
    component: () => import('@views/profile/MainProfile.vue'),
    children: [
      {
        path: '',
        name: 'profile',
        component: () => import('@views/profile/ProfileView.vue')
      }
    ]
  }
]

export default paths
