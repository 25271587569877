import { defineStore } from 'pinia'
import { ref } from 'vue'
import { User } from '@entity/settings/user.entity'
//import { Permission } from '@domain/Account/Permission/Permission'

export const useUserStore = defineStore('user', () => {
  const id = ref<string>('')
  const name = ref<string>('')
  const surname = ref<string>('')
  const email = ref<string>('')
  const isAdmin = ref<boolean>(false)
  const permissions = ref<string[]>([])
  const features = ref<string[]>([])

  const getName = () => name.value
  const fromUser = (user: User) => {
    id.value = user.getId().getValue()
    name.value = user.getName()
    surname.value = user.getSurname()
    email.value = user.getEmail()
    isAdmin.value = user.getIsAdmin()
    features.value = user.getFeatures()
    permissions.value = user.getPermissions()
  }

  const hasPermission = (permission: string) => {
    return isAdmin.value || permissions.value.filter(p => p === permission).length > 0
  }

  const hasFeature = (feature: string) => {
    return features.value.filter(f => f === feature).length > 0
  }

  return {
    id,
    name,
    surname,
    email,

    fromUser,
    hasPermission,
    hasFeature,
    getName
  }
})
