import { RouteRecordRaw } from 'vue-router'

const clientsSettings: Array<RouteRecordRaw> = [
  {
    path: 'clients',
    name: 'settings-clients',
    component: () => import('@views/clients/settings/SettingsView.vue')
  }
]

const diarySettings: Array<RouteRecordRaw> = [
  {
    path: 'diary',
    name: 'settings-diary',
    component: () => import('@views/diary/settings/SettingsView.vue')
  }
]

const filesSettings: Array<RouteRecordRaw> = [
  {
    path: 'files',
    name: 'settings-files',
    component: () => import('@views/files/settings/SettingsView.vue')
  }
]

const paths: Array<RouteRecordRaw> = [
  {
    path: 'settings',
    name: 'settings-main',
    component: () => import('@views/settings/MainSettings.vue'),
    children: [
      {
        path: 'main',
        name: 'settings',
        component: () => import('@views/settings/SettingsView.vue')
      },
      {
        path: 'system',
        name: 'system',
        component: () => import('@views/settings/system/SystemView.vue')
      },
      {
        path: 'system/roles',
        name: 'system-roles',
        component: () => import('@views/settings/system/roles/RoleListView.vue')
      },
      {
        path: 'system/roles/:id',
        name: 'system-roles-details',
        component: () => import('@views/settings/system/roles/RoleDetailsView.vue')
      },
      {
        path: 'system/users',
        name: 'system-users',
        component: () => import('@views/settings/system/users/UserListView.vue')
      },
      {
        path: 'billing',
        name: 'settings-billing',
        component: () => import('@views/billing/settings/SettingsView.vue')
      },
      ...clientsSettings,
      ...diarySettings,
      ...filesSettings
    ]
  }
]

export default paths
