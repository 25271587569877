import { BaseService } from '@service/common/base.services'
import { User } from '@entity/settings/user.entity'
import { useUserStore } from '@/store/account/user.store'

export class MeService extends BaseService {
  static getCurrentUser(): Promise<boolean> {
    return this.get('v1/core/account/user/me').then(data => {
      const user = User.fromAPI(data)
      const userStore = useUserStore()
      userStore.fromUser(user)
      return true
    })
  }
}
