import { createApp } from 'vue'
import App from './App.vue'
import MenuTop from './components/MenuTop.vue'
import TableFilters from './components/TableFilters.vue'

import './registerServiceWorker'
import router from './router'

import { createPinia } from 'pinia'
const pinia = createPinia()

import { meya } from 'meya'
import meyaOptions from '@brand/application/meya.options'

const app = createApp(App)

// eslint-disable-next-line
app.use(meya as any, meyaOptions)

app.use(pinia)
app.use(router)

app.component('MenuTop', MenuTop)
app.component('TableFilters', TableFilters)

app.mount('#app')
