export default {
  core: {
    welcome: 'Welcome {name}!!',
    yes: 'Yes',
    no: 'No',
    save: 'Save',
    accept: 'Accept',
    continue: 'Continue',
    copy: 'Copy',
    show: 'Show',
    sendMail: 'Send Email',
    rol: 'Role',
    roles: 'Roles',
    user: 'User',
    search: 'Search',
    name: 'Name',
    percentage: 'Percentage',
    password: 'Password',
    newPassword: 'New Password',
    rePassword: 'Repeat the password',
    textColor: 'Text color',
    backgroundColor: 'Background color',
    default: 'Default',
    createNewTypeOf: 'Create a new {type} type',
    createNewStatusOf: 'Create a new {type} status',
    updateTypeOf: 'Update a {type} type',
    updateStatusOf: 'Update a {type} status',
    phone: 'Phone',
    countryPrefix: 'Country Prefix',
    address: 'Address',
    web: 'Website',
    email: 'Email',
    bankAccount: 'Bank Account',
    swift: 'SWIFT/BIC',
    typeOf: '{type} type',
    status: 'Status',
    source: 'Source',
    campaign: 'Campaign',
    description: 'Description',
    isInitial: 'Initial',
    parent: 'Parent',
    isFinished: 'Final',
    comments: 'Comments',
    negotiation: 'Negotiation',
    finish: 'Finish',
    finished: 'Finished',
    title: 'Title',
    report: 'Report',
    isDefault: 'Default',
    isAdmin: 'Admin',
    file: 'File',
    folder: 'Folder',
    details: 'Details',
    mime: 'Mime Type',
    location: 'Location',
    time: 'Time',
    toast: {
      success: {
        create: 'Successfully created',
        save: 'Saved successfully',
        update: 'Successfully updated',
        delete: 'Successfully removed'
      },
      error: 'Operation error'
    },
    validations: {
      userMandatory: 'User is required',
      passwordMandatory: 'Password is required',
      passwordMatch: 'Passwords do not match',
      customPassword: 'Must contain 10 characters and must include uppercases, lowecases, and numbers',
      sourceMandatory: 'You must have at least one source',
      arrayMandatory: 'You must have at least one element'
    }
  },
  module: {
    login: {
      login: 'Go to App',
      recoveryPassword: 'Recovery Password',
      recoveryPasswordSuccess: 'An email has been sent to recover the password',
      invalidToken: 'Invalid token',
      confirmLogout: 'Are you sure you want to log out?'
    },
    dashboard: {
      title: 'Home'
    },
    files: {
      title: 'Files',
      fileTypes: 'File Types',
      fileType: 'File Type',
      newFolder: 'New Folder',
      newFile: 'New File',
      editFile: 'Edit File',
      editFolder: 'Edit Folder'
    },
    diary: {
      title: 'Diary',
      event: 'Event',
      defaultTime: 'Default Time',

      settings: {
        eventTypes: 'Event Types',
        eventStatus: 'Event Status',
        workingHours: 'Working Hours'
      }
    },
    clients: {
      title: 'Contacts',
      client: 'Client',
      contact: 'Contact',
      company: 'Company',
      lead: 'Lead',
      newClient: 'New Client',
      personalInfo: 'Personal Information',

      settings: {
        sources: 'Sources',
        clientStatus: 'Client Statuses',
        negotiationStatus: 'Negotiation Statuses',
        channelSources: 'Channel Sources',
        phoneTypes: 'Phone Types',
        isSmsAllowed: 'SMS Allowed',
        emailTypes: 'Email Types',
        clientTypes: 'Client Types',
        addressTypes: 'Address Types',
        webTypes: 'Web Types',
        campaignSources: 'Campaign Sources',
        reportTypes: 'Report Types',
        relationships: 'Relationships',
        relationship: 'Relationship',
        inverseRelationships: 'Inverse Relationships',
        customFields: 'Custom Fields'
      },
      address: {
        country: 'Country',
        state: 'State',
        city: 'City',
        street: 'Street',
        number: 'Number',
        extra: 'Extra',
        zipCode: 'Zip Code'
      },
      negotiations: {
        reports: {
          newReport: 'New Report',
          report: 'Report'
        },
        edit: 'Edit Negotiation',
        new: 'New Negotiation',
        confirmFinish: 'Are you sure you want to finish the negotiation?'
      }
    },
    settings: {
      title: 'Settings',
      system: {
        title: 'System',
        configurationCRM: 'CRM Configuration',
        profiles: 'Profiles',
        dateUpdate: 'Date Update',
        dateCreate: 'Date Create',
        roles: {
          title: 'Roles',
          newRole: 'New Rol',
          editRole: 'Edit Rol',
          permissions: 'Permissions'
        },
        users: {
          title: 'Users',
          newUser: 'New User',
          editUser: 'Edit User',
          changePassword: 'Change Password',
          info: 'User Info'
        }
      }
    },
    billing: {
      title: 'Billing',
      tax: {
        title: 'Taxes',
        newTax: 'New Tax',
        editTax: 'Edit Tax'
      },
      paymentMethod: {
        title: 'Payment Methods',
        newPaymentMethod: 'New Payment Method',
        editPaymentMethod: 'Edit Payment Method'
      },
      settings: {
        tax: 'Tax',
        paymentMethod: 'Payment Method'
      }
    }
  }
}
