import { MeyaOptions } from 'meya'
import es from './trads/es.trad'
import en from './trads/en.trad'
import customTheme from './theme'

interface ConfirmNotifyOptions {
  handleCancel: boolean
  width: string
  successButtonText: string
  cancelButtonText: string
}

const meyaOptions: MeyaOptions = {
  i18n: {
    trads: { es, en },
    default: 'es'
  },
  theme: {
    theme: customTheme,
    locale: 'es'
  },
  notify: {
    confirm: {
      successButtonText: 'core.continue',
      cancelButtonText: 'core.cancel'
    } as ConfirmNotifyOptions
  }
}

export default meyaOptions
