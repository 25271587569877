import { RouteRecordRaw } from 'vue-router'

const paths: Array<RouteRecordRaw> = [
  {
    path: 'clients',
    name: 'clients-main',
    component: () => import('@views/clients/MainClients.vue'),
    children: [
      {
        path: '',
        name: 'clients',
        component: () => import('@views/clients/list/ClientsListView.vue')
      },
      {
        path: ':id',
        name: 'client-details',
        component: () => import('@views/clients/details/ClientDetailsView.vue'),
        children: [
          {
            path: 'information',
            name: 'client-details-information',
            component: () => import('@views/clients/details/tabs/ClientInformationTab.vue')
          },
          {
            path: 'negotiation',
            name: 'client-details-negotiation',
            component: () => import('@views/clients/details/tabs/ClientNegotiationTab.vue')
          },
          {
            path: 'events',
            name: 'client-details-events',
            component: () => import('@views/clients/details/tabs/ClientEventsTab.vue')
          },
          {
            path: 'files',
            name: 'client-details-files',
            component: () => import('@views/clients/details/tabs/ClientFilesTab.vue')
          }
        ]
      }
    ]
  }
]

export default paths
