import { Uuid } from 'meya'
import { BaseEntity } from '@entity/common/base.entity'

interface UserAPIResponse {
  id: string
  name: string
  surname: string
  fullname: string
  email: string
  is_admin: boolean
  permissions: string[]
  features: string[]
  modules: string[]
}

export class User extends BaseEntity {
  readonly id: Uuid
  readonly name: string
  readonly surname: string
  readonly fullname: string
  readonly email: string
  readonly isAdmin: boolean
  readonly permissions: string[]
  readonly features: string[]
  readonly modules: string[]

  constructor(
    id: Uuid,
    name: string,
    surname: string,
    fullname: string,
    email: string,
    isAdmin: boolean,
    permissions: string[],
    features: string[],
    modules: string[]
  ) {
    super()
    this.id = id
    this.name = name
    this.surname = surname
    this.fullname = fullname
    this.email = email
    this.isAdmin = isAdmin
    this.permissions = permissions
    this.features = features
    this.modules = modules
  }

  static fromAPI(data: UserAPIResponse): User {
    return new User(
      Uuid.fromString(data.id),
      data.name,
      data.surname,
      data.fullname,
      data.email,
      data.is_admin,
      data.permissions,
      data.features,
      data.modules
    )
  }

  public getId(): Uuid {
    return this.id
  }

  public getSurname(): string {
    return this.surname
  }

  public getName(): string {
    return this.name
  }

  public getEmail(): string {
    return this.email
  }

  public getFullname(): string {
    return this.fullname
  }

  public getIsAdmin(): boolean {
    return this.isAdmin
  }

  public getPermissions(): string[] {
    return this.permissions
  }

  public getFeatures(): string[] {
    return this.features
  }

  public getModules(): string[] {
    return this.modules
  }
}
