import { RouteRecordRaw } from 'vue-router'
import SettingsRoutes from './modules/settings'
import ProfileRoutes from './modules/profile'
import ClientsRoutes from './modules/clients'
import DiaryRoutes from './modules/diary'

const paths: Array<RouteRecordRaw> = [
  {
    path: '',
    name: 'base',
    component: () => import('@views/MainView.vue'),
    children: [
      {
        path: '/:pathMatch(.*)*',
        component: () => import('@views/common/NotFoundView.vue')
      },
      {
        path: 'not-permission',
        name: 'not-permission',
        component: () => import('@views/common/NotPermissionView.vue')
      },
      {
        path: 'home',
        name: 'home',
        component: () => import('@views/dashboard/DashboardView.vue')
      },
      ...SettingsRoutes,
      ...ProfileRoutes,
      ...ClientsRoutes,
      ...DiaryRoutes
    ]
  }
]

export default paths
