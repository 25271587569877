const es = {
  core: {
    welcome: 'Bienvenido {name} !!',
    yes: 'Si',
    no: 'No',
    save: 'Guardar',
    accept: 'Aceptar',
    continue: 'Continuar',
    user: 'Usuario',
    password: 'Contraseña',
    newPassword: 'Nueva contraseña',
    rePassword: 'Repetir la contraseña',
    create: 'Crear',
    add: 'Añadir',
    edit: 'Editar',
    close: 'Cerrar',
    search: 'Buscar',
    searchBy: 'Buscar por {items}',
    cancel: 'Cancelar',
    delete: 'Eliminar',
    update: 'Actualizar',
    copy: 'Copiar',
    show: 'Mostrar',
    sendMail: 'Enviar Email',
    name: 'Nombre',
    percentage: 'Porcentaje',
    surname: 'Apellidos',
    rol: 'Rol',
    roles: 'Roles',
    id: 'Id',
    itemsPerPage: 'Items por página',
    cleanFilters: 'Limpiar filtros',
    hide: 'Ocultar',
    others: 'Otros',
    language: 'Idioma',
    loading: 'Cargando...',
    default: 'Por defecto',
    createNewTypeOf: 'Crear nuevo tipo de {type}',
    createNewStatusOf: 'Crear nuevo Estado de {type}',
    updateTypeOf: 'Actualizar tipo de {type}',
    updateStatusOf: 'Actualizar Estado de {type}',
    typeOf: 'Tipo de {type}',
    phone: 'Teléfono',
    countryPrefix: 'Prefijo de País',
    address: 'Dirección',
    web: 'Web',
    email: 'Email',
    bankAccount: 'Cuenta Bancaria',
    swift: 'SWIFT/BIC',
    status: 'Estado',
    source: 'Origen',
    report: 'Reporte',
    campaign: 'Campaña',
    description: 'Descripción',
    backgroundColor: 'Color de Fondo',
    textColor: 'Color de Texto',
    isInitial: 'Inicial',
    parent: 'Padre',
    isFinished: 'Final',
    comments: 'Comentarios',
    negotiation: 'Negociación',
    finish: 'Finalizar',
    finished: 'Finalizado',
    title: 'Título',
    isDefault: 'Por defecto',
    isAdmin: 'Administrador',
    open: 'Abrir',
    folder: 'Carpeta',
    file: 'Archivo',
    details: 'Detalles',
    mime: 'Tipo MIME',
    size: 'Tamaño',
    location: 'Ubicación',
    time: 'Hora',
    validations: {
      userMandatory: 'Usuario es obligatiorio',
      emailMandatory: 'Email es obligatiorio',
      passwordMandatory: 'Contraseña es obligatoria',
      roleMandatory: 'Rol es obligatoria',
      passwordMatch: 'Las contraseñas no coinciden',
      customPassword: 'Debe contener 10 caracteres y debe incluir mayúsculas, minúsculas y números',
      fieldMandatory: 'Campo obligatorio',
      sourceMandatory: 'Debe tener al menos un origen',
      urlInvalid: 'URL no válida',
      arrayMandatory: 'Debe tener al menos un elemento.'
    },
    toast: {
      success: {
        create: 'Creado con éxito',
        save: 'Guardado con éxito',
        update: 'Actualizado con éxito',
        delete: 'Eliminado con éxito'
      },
      copied: 'Copiado al portapapeles',
      error: 'Error en la operación'
    },
    confirmation: {
      delete: '¿Estás seguro que quieres eliminarlo?'
    }
  },
  sidebar: {
    contextual: {
      myNotes: 'Mis Notas',
      profile: 'Perfil',
      help: 'Ayuda',
      closeSession: 'Cerrar Sesión'
    }
  },
  module: {
    login: {
      login: 'Entrar',
      recoveryPassword: 'Recuperar Contraseña',
      recoveryPasswordSuccess: 'Se ha enviado un email para recuperar la contraseña',
      invalidToken: 'Token inválido',
      confirmLogout: '¿Estás seguro que quieres cerrar la sesión?'
    },
    dashboard: {
      title: 'Inicio',
      editPanel: 'Editar Panel',
      savePanel: 'Guardar Panel',
      addWidget: 'Añadir Widget'
    },
    clients: {
      title: 'Contactos',
      client: 'Cliente',
      searchTitle: 'Nombre del Cliente',
      lead: 'Lead',
      newClient: 'Nuevo Cliente',
      personalInfo: 'Información Personal',
      clientUser: 'Usuario',
      infoClient: 'Información del Cliente',
      notInfo: 'No hay información',
      clientType: 'Tipo de Cliente',
      noClientsTitle: '¡Vaya! Parece que todavía no tienes ningún cliente',
      noClientsSubtitle: '¿Comenzamos con el primero?',
      noClientsSearch: 'Ooops! No hemos encontrado ningun cliente para esta búsqueda',

      tabs: {
        information: 'Información',
        history: 'Historial',
        diary: 'Agenda',
        files: 'Archivos'
      },

      settings: {
        sources: 'Origenes',
        clientStatus: 'Estados de Cliente',
        negotiationStatus: 'Estados de Negociación',
        channelSources: 'Orígenes de canal',
        campaignSources: 'Orígenes de campaña',
        reportTypes: 'Tipos de Reportes',
        phoneTypes: 'Tipos de Teléfono',
        emailTypes: 'Tipos de Email',
        isSmsAllowed: 'Permitir SMS',
        clientTypes: 'Tipos de Cliente',
        addressTypes: 'Tipos de Dirección',
        webTypes: 'Tipos de Web',
        relationships: 'Relaciones',
        relationship: 'Relación',
        inverseRelationships: 'Relaciones Inversas',
        customFields: 'Campos Personalizados',
        dateUpdate: 'Fecha de Actualización',
        dateCreate: 'Fecha de Creación',
        finished: 'Finalización'
      },
      address: {
        country: 'País',
        state: 'Estado',
        city: 'Ciudad',
        street: 'Calle',
        number: 'Número',
        extra: 'escalera, piso, puerta...',
        zipCode: 'Código Postal'
      },
      negotiations: {
        notNegotiation: {
          title: '¡Vaya! Parece que todavía no se ha registrado ninguna acción con este cliente',
          subtitle: 'Comencemos una bonita historia'
        },
        reports: {
          newReport: 'Nuevo Reporte',
          report: 'Reporte'
        },
        edit: 'Editar Negociación',
        new: 'Nueva Negociación',
        confirmFinish: '¿Estás seguro que quieres finalizar la negociación?'
      }
    },
    diary: {
      title: 'Agenda',
      event: 'Evento',
      defaultTime: 'Tiempo por defecto',
      finished: 'Finalizado',

      settings: {
        eventTypes: 'Tipos de Evento',
        eventStatus: 'Estados de Evento',
        workingHours: 'Horario Laboral'
      }
    },
    files: {
      title: 'Archivos',
      fileTypes: 'Tipos de Archivo',
      fileType: 'Tipo de Archivo',
      newFolder: 'Nueva Carpeta',
      newFile: 'Nuevo Archivo',
      editFile: 'Editar Archivo',
      editFolder: 'Editar Carpeta'
    },
    settings: {
      title: 'Configuración',
      system: {
        title: 'Sistema',
        configurationCRM: 'Configuración CRM',
        profiles: 'Perfiles',

        roles: {
          title: 'Roles',
          newRole: 'Nuevo Rol',
          editRole: 'Editar Rol',
          permissions: 'Permisos'
        },
        users: {
          title: 'Usuarios',
          newUser: 'Nuevo Usuario',
          editUser: 'Editar Usuario',
          changePassword: 'Cambiar Contraseña',
          info: 'Información de Usuario'
        }
      }
    },
    billing: {
      title: 'Facturación',
      tax: {
        title: 'Impuestos',
        newTax: 'Nuevo Impuesto',
        editTax: 'Editar Impuesto'
      },
      paymentMethod: {
        title: 'Métodos de Pago',
        newPaymentMethod: 'Nuevo Método de Pago',
        editPaymentMethod: 'Editar Método de Pago'
      },
      settings: {
        tax: 'Impuesto',
        paymentMethod: 'Método de Pago'
      }
    }
  }
}

export default es
