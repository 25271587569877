import { defineStore } from 'pinia'
import { ref } from 'vue'
import { BlobDocument, device, Uuid } from 'meya'
import { UserService } from '@service/settings/user.service'
import { useUserStore } from '@/store/account/user.store'

export const useMenuSidebarStore = defineStore('menuSidebar', () => {
  const visible = ref<boolean>(device.isMdAndUp())
  const toggle = () => (visible.value = !visible.value)
  const userImage = ref<string>('')
  const userStore = useUserStore()

  const getProfileImage = () => {
    if (!userStore.id) {
      return
    }

    UserService.getProfileImage(Uuid.fromString(userStore.id))
      .then((res: BlobDocument) => {
        res.toBase64().then(img => (userImage.value = img))
      })
      .catch(e => {
        console.log('Error getting profile image', e)
        userImage.value = ''
      })
  }

  const reloadProfileImage = () => {
    userImage.value = ''
    getProfileImage()
  }

  getProfileImage()

  return {
    visible,
    toggle,
    userImage,

    getProfileImage,
    reloadProfileImage
  }
})
