<template>
  <lca-app-bar :nav-icon="device.isSmAndDown()" @click:nav-icon="menuSidebarStore.toggle()">
    <template #title>
      <lca-button v-if="btnBack" icon="fa-solid fa-chevron-left" text @click="goBack()" />
      <slot name="title"></slot>
    </template>

    <template #append>
      <slot name="append"></slot>
    </template>
  </lca-app-bar>
</template>

<script setup lang="ts">
import { device } from 'meya'
import { useMenuSidebarStore } from '@stores/components/menuSidebar.store'
import { useRouter } from 'vue-router'

const menuSidebarStore = useMenuSidebarStore()
const router = useRouter()

const props = defineProps<{
  btnBack?: string | object | boolean
}>()

const goBack = () => {
  if (props.btnBack) {
    if (typeof props.btnBack === 'string') {
      router.push({ name: props.btnBack })
    } else if (typeof props.btnBack === 'boolean') {
      router.go(-1)
    } else {
      router.push(props.btnBack)
    }
  }
}
</script>
