<template>
  <lca-app>
    <div v-if="loading">
      <lca-loading />
    </div>
    <router-view v-else />
  </lca-app>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { security } from 'meya'
import { MeService } from '@service/me.service'

const router = useRouter()
const route = useRoute()
const loading = ref(true)

if (!security.hasSession()) {
  // Si NO es una ruta privada, mandamos al login
  if (!(route.meta?.auth_check === false) && route.name !== undefined) {
    router.push({ name: 'login' })
  }
  loading.value = false
} else {
  MeService.getCurrentUser()
    .then(() => {
      loading.value = false
    })
    .catch(() => {
      router.push({ name: 'login' })
      loading.value = false
    })
}
</script>
<style lang="scss" scoped>
.lca-main {
  height: 100vh;
}
</style>
